<template>
  <div class="activity page-container">
    <PageTitle title="活動辦法" />
    <div class="activity_content">
      <vue-scroll :ops="ops">
        <ul v-if="newEvent">
          <li>
            <span class="activity_title">活動日期：</span>
            即日起至2023年11月30日(購買發票及登錄日期皆至2023年11月30日23點59分59秒止)
          </li>
          <br />
          <li><span class="activity_title">點數兌換獎項：</span></li>
          <ol class="decimal">
            <li>
              SNOW PEAK
              拱型帳天幕組(SET-250RH)，市價NT$18,300，250點兌換一份，限量1份
            </li>
            <li>
              SNOW PEAK 延伸型桌(CK-090)，市價NT$13,650，200點兌換一份，限量1份
            </li>
            <li>
              SNOW PEAK
              休閒椅30-卡其(LV-091KH)，市價NT$5,900，90點兌換一份，限量3份
            </li>
            <li>
              SNOW PEAK
              收納置物箱-25(UG-025G)，市價NT$4,000，60點兌換一份，限量7份
            </li>
            <li>
              SNOW PEAK
              保冷罐500ml(TW-505)，市價NT$1,680，25點兌換一份，限量30份
            </li>
            <li>
              SNOW PEAK
              保冷罐350ml(TW-355)，市價NT$1,320，20點兌換一份，限量30份
            </li>
            <li>
              SNOW PEAK Trek鋁合金個人鍋-900
              (SCS-008)，市價NT$990，15點兌換一份，限量30份
            </li>
            <li>
              SNOW PEAK
              SP鈦金屬叉匙組(SCT-002)，市價NT$920，15點兌換一份，限量30份
            </li>
          </ol>
          <br />
          <li><span class="activity_title">活動辦法：</span></li>
          <ol class="decimal">
            <li>
              即日起至2023年11月30日止，購買ASAHI SUPER DRY
              罐裝350ml、罐裝500ml、瓶裝334ml、瓶裝633ml、現壓生啤酒，憑購買發票即可上網登錄參加發票登錄集點兌換贈品活動。
            </li>
            <li>
              登錄之發票明細中需清楚載明ASAHI SUPER
              DRY商品名稱。若驗證發現有相關資訊不符、商品品項名稱不符等問題，將喪失集點、該品項兌獎資格。
            </li>
            <li>可使用原帳戶之點數兌換或累積點數。</li>
            <li>
              本次活動贈品須於2023年11月30日前兌換完畢，數量有限，兌完為止。
            </li>
            <li>
              上傳發票照片須清楚顯示、完整不得有摺痕、缺角避免無法辨識，一次上傳一張，不得重複上傳，重複上傳將視為無效發票不具集點與兌獎資格。
            </li>
            <li>
              上傳之發票需經過財政部系統審核或人工審核，至多需5-7個工作天，電子發票實際時間依財政部系統實際審核時間而定，並請自行至「點數查詢」專區查詢進度。
            </li>
            <li>詳細活動實施辦法，依實際執行內容為主。</li>
            <li>
              臺灣朝日啤酒股份有限公司將本平台之活動包含發票登錄、集點活動、兌獎等，包含但不限於，委由承辦單位和亞有限公司與三把刷子內容工場股份有限公司與達思智能科技股份有限公司共同執行。
            </li>
          </ol>
          <br />
          <li><span class="activity_title">兌獎方式：</span></li>
          <ol class="decimal">
            <li>
              活動將於即日起至2023年11月30日止，購買ASAHI SUPER DRY
              罐裝350ml、罐裝500ml、瓶裝334ml、瓶裝633ml、現壓生啤酒，憑購買發票即可進入活動頁面參加發票登錄集點活動，符合資格者可進行點數累積（單張發票每滿100元即獲得1點），並於符合兌換門檻時至兌獎專區兌換贈品。
            </li>
            <li>
              贈品數量有限，兌完為止（將以會員提出兌換申請的時間序作為贈品贈送順序的依據），以提出兌換申請的時間點為準。提出兌換申請後，贈品將於隔月月底前統整兌換數量後統一安排寄送(舉例：9/1-9/30所提出之申請，會於10月底前統整後寄出)，主辦單位保有活動修改、終止、解釋之權利。
            </li>
            <li>
              兌換獎項以公告說明為準，如若因SNOW
              PEAK庫存異動造成無法提供兌換，活動單位將改以其他等值商品兌換，不得異議。
            </li>
            <li>
              根據中華民國稅法規定，兌獎獎項金額若超過新台幣1,000元，兌獎人須依規定填寫並繳交相關兌獎收據。無法配合者則視為自動棄權，不具兌獎資格且不另行通知，且不得異議。單一用戶兌換其獎值累積超過新台幣20,000元者，兌獎者需依法繳交機會中獎所得稅10%。如果兌獎者不願意給付得獎商品之稅額或不願意提出身分證明，則視為兌獎者自動棄權，不具兌獎資格且不另行通知。
            </li>
            <li>
              贈品寄送資料以會員中心登錄之資料為主，若要更改寄送地址請自行至會員中心更改，凡因收件人姓名、收件地址、連絡電話等資訊填寫不完全、無法辨識、填寫錯誤而導致無法寄達，將視同棄權、無法再次安排寄送且不另行通知，不得異議。
            </li>
            <li>獎項配送地點僅限台灣、澎湖、金門、馬祖地區。</li>
            <li>
              申請使用點數兌獎後，不得提出點數撤回、獎品更換為其他商品或現金。
            </li>
          </ol>
          <br />
          <li><span class="activity_title">注意事項：</span></li>
          <ol class="decimal" style="margin-left: 8px">
            <li>
              消費者於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範；如有違反，主辦單位得取消其參加及獲獎資格，並得就因此所產生之損害，向該參加者請求損害賠償。
            </li>
            <li>
              <span class="activity_point">
                本活動僅開放給擁有中華民國國籍或擁有長期居留證者加入會員；且依中華民國菸酒管理法，參加者須年滿18歲以上，若經查為未滿18歲者，將無條件取消參加資格。
              </span>
            </li>
            <li>
              中獎人收到得獎通知後，需願意提供相關身分證明以利查核是否已年滿18歲以上，若不願提供身分證明文件或未滿18歲，將取消得獎資格且不另行通知，不得異議。
            </li>
            <li>
              本活動如有任何因電腦、網路、電話、技術或不可歸責於主辦單位之事由，而使系統誤送活動訊息或中獎通知，或使參加本活動者所寄出或登錄之資料有遲延、遺失、錯誤、無法辨識或毀損之情況，主辦單位不負任何法律責任，參加者亦不得因此異議。
            </li>
            <li>
              任何破壞活動進行、破壞網站及網站管理者之運作，違反網站及網站管理者的使用條款，或冒用、偽造第三人資料參加本活動者，主辦單位有權取消其參加本活動之權利。
            </li>
            <li>
              網頁及各式宣傳物上之圖片僅供參考，獎項以實物為準，主辦單位保留更換贈品之權利。本活動之獎項不得轉換、轉讓或折換現金，並禁止在網路販售、轉讓，若經查證屬實，將取消得獎資格且不另行通知，不得異議。
            </li>
            <li>
              承辦單位僅使用參加者所提供之個人資料於活動用途，且遵守【個人資料保護法】相關規定，不惡意洩漏及作為他用，以維護參加者權益。參加者同意兌獎相關個人資訊由主辦單位於活動範圍內進行蒐集、電腦處理及利用，不做其他用途。
            </li>
            <li>
              參與者需為本人同意填寫且提出資料均為真實，並未冒用或盜用任何第三人之資料。經查證若有冒名、盜用等情形發生時，將無條件取消參加資格且負起相關法律責任，主辦單位將保留法律追訴權，並得就因此所產生之損害，向該參加者請求損害賠償。
            </li>
            <li>
              如未提供正確資訊或未詳細填寫個人基本資料，以致獎品寄送時導致無法寄達、退件，視同放棄得獎資格，亦不再另行通知。
            </li>
            <li>
              獎項詳細內容與規格以實物為準，網頁 / 產品包裝 /
              門市佈置物上圖片僅供參考，獎項均不得轉換、轉讓或折換現金。
            </li>
            <li>
              運送過程中，若發生任何毀壞、錯遞、延遲或遺失，非自然疏失造成者，本公司臺灣朝日啤酒股份有限公司與承辦單位恕不負責。
            </li>
            <li>
              本活動因故無法進行時，主辦單位保有解釋、修正、暫停或終止本活動之權利，如有任何變更將公佈於網頁中，恕不另行通知。其他未盡事宜，悉依主辦單位相關規定及解釋為準。
            </li>
            <li>
              如有任何發票集點活動相關問題，歡迎隨時於LINE帳號中訊息發問，或可來信訊息社群平台「ASAHI Beer Taiwan」，承辦單位將於每週一至週五上午10：00至下午6：00，回覆訊息並進行處理相關程序。
            </li>
          </ol>
        </ul>
        <ul v-else>
          <li>
            <span class="activity_title">活動日期：</span>
            即日起至2023年08月31日(購買發票及登錄日期皆至2023年08月31日23點59分59秒止)
          </li>
          <br />
          <li><span class="activity_title">點數兌換獎項：</span></li>
          <ol class="decimal">
            <li>NEW ASAHI SUPER DRY變色杯，20點兌換一份，限量1,000份</li>
            <li>ASAHI SUPER DRY保冷袋，30點兌換一份，限量500份</li>
            <li>NEW ASAHI SUPER DRY手提箱，50點兌換一份，限量100份</li>
            <li>
              高雄洲際酒店雙人住宿券，100點兌換一張，限量5張(市值6,700元/張，含兩客早餐/僅限非國定假日，週日至週四使用)
            </li>
          </ol>
          <br />
          <li><span class="activity_title">抽獎獎項：</span></li>
          <ol class="disc">
            <li>
              東京單人來回機票一張，限量3張(市值25,000元/張)
              <br />
              東京單人來回機票抽獎時間：2023年09月04日進行抽獎
              <br />
              東京單人來回機票公告時間：2023年09月11日公告並與中獎人聯繫
            </li>
          </ol>
          <br />
          <li><span class="activity_title">活動辦法：</span></li>
          <ol class="decimal">
            <li>
              即日起至2023年08月31日止，購買ASAHI SUPER DRY
              135ml、334ml、350ml、500ml、633ml瓶裝或罐裝，憑購買發票即可上網登錄參加發票登錄集點兌換贈品活動。
            </li>
            <li>
              即日起登錄之購買發票號碼限2023年07月01日至2023年08月31日期間開立為有效，發票明細中有載明ASAHI
              SUPER
              DRY的商品相關名稱。若驗證發現並非於有效期間之發票日期、發票字軌及號碼不符，商品品項名稱不符，將喪失集點、該品項兌獎資格。
            </li>
            <li>可使用原帳戶之點數兌換或累積點數。</li>
            <li>
              本次活動贈品須於2023年08月31日前兌換完畢，數量有限，兌完為止。
            </li>
            <li>
              東京單人來回機票抽獎資格：於活動期間2023年07月01日至2023年08月31日內兌換任1點點數，即獲得抽獎機會。
            </li>
            <li>
              上傳發票照片須清楚顯示、完整不得有摺痕、缺角避免無法辨識，一次上傳一張，不得重複上傳，重複上傳將視為無效發票不具集點與兌獎資格。
            </li>
            <li>
              上傳之發票需經過財政部系統審核或人工審核，至多需5-7個工作天，電子發票實際時間依財政部系統實際審核時間而定，並請自行至「點數查詢」專區查詢進度。
            </li>
            <li>詳細活動實施辦法，依實際執行內容為主。</li>
            <li>
              臺灣朝日啤酒股份有限公司將本平台之活動包含發票登錄、集點活動、兌獎等，包含但不限於，委由承辦單位三把刷子內容工場股份有限公司與達思智能科技股份有限公司共同執行。
            </li>
          </ol>
          <br />
          <li><span class="activity_title">兌獎方式：</span></li>
          <ol class="decimal">
            <li>
              活動將於即日起至2023年08月31日止，憑購買ASAHI SUPER DRY
              135ml、334ml、350ml、500ml、633ml瓶裝或罐裝之發票，進入活動頁面參加發票登錄集點活動，符合資格者可進行點數累積（單張發票每滿100元即獲得1點），並於符合兌換門檻時至兌獎專區兌換贈品。
            </li>
            <li>
              贈品數量有限，兌完為止（將以會員提出兌換申請的時間序作為贈品贈送順序的依據），以提出兌換申請的時間點為準。提出兌換申請後，贈品將於隔月月底前統整兌換數量後統一安排寄送(舉例：7/1-7/31所提出之申請，會於8月底前統整後寄出)，主辦單位保有活動修改、終止、解釋之權利。（高雄洲際酒店雙人住宿券＆東京單人來回機票，請見下方詳細說明）
            </li>
            <li>
              根據中華民國稅法規定，兌獎獎項金額若超過新台幣1,000元，兌獎人須依規定填寫並繳交相關兌獎收據。無法配合者則視為自動棄權，不具兌獎資格且不另行通知，且不得異議。獎項價值超過新台幣20,000元者，兌獎者需依法繳交機會中獎所得稅10%。如果兌獎者不願意給付得獎商品之稅額或不願意提出身分證明，則視為兌獎者自動棄權，不具兌獎資格且不另行通知。
            </li>
            <li>
              高雄洲際酒店雙人住宿券兌換說明
              <ol class="circle">
                <li>
                  活動獎項贈品項目、內容以ASAHI SUPER
                  DRY提供之票券為準，中獎者不得要求更改獎項、房型、退換、轉售或兌換現金。
                </li>
                <li>
                  「高雄洲際酒店雙人住宿券」兌換及加價等住宿規範依該酒店公告與票券載明內容為準；實際住宿如遇金額不足應由中獎者自行補足，若住宿券價額高於實際住宿價格多出之部分視同放棄，中獎者不得就差額請求退還現金或折抵任何費用。
                </li>
                <li>
                  若因不可抗力之原因致使無法提供原有獎品，ASAHI SUPER
                  DRY保有隨時更換獎品之權利，中獎人不得向主辦單位請求任何權利與補償。
                </li>
                <li>
                  本住宿券2023年09月開始即可使用住宿券，有效期限至2024年03月31日止。本住宿券限週日至週四使用。如需週五、週六使用，中獎人需自行補差額。國定例假日及連假皆恕無法使用。
                </li>
              </ol>
            </li>
            <li>
              東京來回機票兌換說明
              <ol class="circle">
                <li>
                  活動獎項贈品項目、內容以ASAHI SUPER
                  DRY提供之票券為準，中獎者不得要求更改獎項、艙等、退換、轉售或兌換現金。
                </li>
                <li>
                  本兌換憑證應於2024/07/31前開立，2024/12/31前之航班機票完成，若逾期視同為使用者放棄，不得向朝日啤酒/山富國際旅行社請求任何權力與補償。
                </li>
                <li>
                  本活動贈品機票所屬航空公司預計為：中華航空/長榮航空/星宇航空/國泰航空其中之一。中獎者屆時出發機票如遇金額不足應由中獎者自行補足；若實際機票價格高於本贈品機票帳面價格多出之部分視同放棄，中獎者不得就差額請求退還現金或折抵任何費用。
                </li>
                <li>
                  本專案機票適用於兌換成人/兒童(2至11歲)機票。無法兌換嬰兒機票。
                </li>
                <li>手提行李及寄艙行李等相關規定，依指定航空公司規定為準。</li>
                <li>
                  逾期未經兌換/使用之機票兌換券，不得要求延長效期、重新開票，且不得要求更換及退費。
                </li>
                <li>
                  機票遇連續假日、寒假、暑假、特定日期或旺季；以及因遇展覽會議、當地舉辦慶典活動、連續假日、寒假或特定日期等特殊狀況時，航空公司將另有價格調漲之公告及規定，持劵人此時需補足差額。
                </li>
                <li>
                  本憑證所兌換之商品內容，未含兩地機場稅、燃油稅等費用，機票之機場稅、兵險、燃油稅等各項相關稅金，均由中獎人自行負擔。
                </li>
                <li>
                  本專案之機票開票後，更改機票費、未依時搭機費用等各項規定依航空公司各艙等/票價品牌/訂位子艙等/機票效期之規定辦理。
                </li>
                <li>
                  開票後亦不得轉讓、辦理退票、更改行程或轉讓其他航空公司。
                </li>
                <li>
                  ASAHI SUPER
                  DRY保有最終決定或撤銷權。活動如有更改，恕不另行通知。
                </li>
                <li>未盡事項後續會再由專員與兌獎者聯繫。</li>
              </ol>
            </li>
            <li>
              贈品寄送資料以會員中心登錄之資料為主，若要更改寄送地址請自行至會員中心更改，凡因收件人姓名、收件地址、連絡電話等資訊填寫不完全、無法辨識、填寫錯誤而導致無法寄達，將視同棄權、無法再次安排寄送且不另行通知，不得異議。
            </li>
            <li>獎項配送地點僅限台灣、澎湖、金門、馬祖地區。</li>
            <li>
              申請使用點數兌獎後，不得提出點數撤回、獎品更換為其他商品或現金。
            </li>
          </ol>
          <br />
          <li><span class="activity_title">注意事項：</span></li>
          <ol class="decimal" style="margin-left: 8px">
            <li>
              消費者於參加本活動之同時，即同意接受本活動之活動辦法與注意事項之規範；如有違反，主辦單位得取消其參加及獲獎資格，並得就因此所產生之損害，向該參加者請求損害賠償。
            </li>
            <li>
              <span class="activity_point">
                本活動僅開放給擁有中華民國國籍或擁有長期居留證者加入會員
              </span>
              ；且依中華民國菸酒管理法，參加者須年滿18歲以上，若經查為未滿18歲者，將無條件取消參加資格。
            </li>
            <li>
              中獎人收到得獎通知後，需願意提供相關身分證明以利查核是否已年滿18歲以上，若不願提供身分證明文件或未滿18歲，將取消得獎資格且不另行通知，不得異議。
            </li>
            <li>
              本活動如有任何因電腦、網路、電話、技術或不可歸責於主辦單位之事由，而使系統誤送活動訊息或中獎通知，或使參加本活動者所寄出或登錄之資料有遲延、遺失、錯誤、無法辨識或毀損之情況，主辦單位不負任何法律責任，參加者亦不得因此異議。
            </li>
            <li>
              任何破壞活動進行、破壞網站及網站管理者之運作，違反網站及網站管理者的使用條款，或冒用、偽造第三人資料參加本活動者，主辦單位有權取消其參加本活動之權利。
            </li>
            <li>
              網頁及各式宣傳物上之圖片僅供參考，獎項以實物為準，主辦單位保留更換贈品之權利。本活動之獎項不得轉換、轉讓或折換現金，並禁止在網路販售、轉讓，若經查證屬實，將取消得獎資格且不另行通知，不得異議。
            </li>
            <li>
              承辦單位僅使用參加者所提供之個人資料於活動用途，且遵守【個人資料保護法】相關規定，不惡意洩漏及作為他用，以維護參加者權益。參加者同意兌獎相關個人資訊由主辦單位於活動範圍內進行蒐集、電腦處理及利用，不做其他用途。
            </li>
            <li>
              參與者需為本人同意填寫且提出資料均為真實，並未冒用或盜用任何第三人之資料。經查證若有冒名、盜用等情形發生時，將無條件取消參加資格且負起相關法律責任，主辦單位將保留法律追訴權，並得就因此所產生之損害，向該參加者請求損害賠償。
            </li>
            <li>
              如未提供正確資訊或未詳細填寫個人基本資料，以致獎品寄送時導致無法寄達、退件，視同放棄得獎資格，亦不再另行通知。
            </li>
            <li>
              獎項詳細內容與規格以實物為準，網頁 / 產品包裝 /
              門市佈置物上圖片僅供參考，獎項均不得轉換、轉讓或折換現金。
            </li>
            <li>
              運送過程中，若發生任何毀壞、錯遞、延遲或遺失，非自然疏失造成者，本公司臺灣朝日啤酒股份有限公司與承辦單位恕不負責。
            </li>
            <li>
              本活動因故無法進行時，主辦單位保有解釋、修正、暫停或終止本活動之權利，如有任何變更將公佈於網頁中，恕不另行通知。其他未盡事宜，悉依主辦單位相關規定及解釋為準。
            </li>
            <li>
              如有任何發票集點活動相關問題，歡迎隨時來信訊息社群平台「ASAHI Beer
              Taiwan」或於LINE帳號中訊息發問，承辦單位將於每週一至週五上午10：00至下午6：00，回覆訊息並進行處理相關程序。
            </li>
          </ol>
        </ul>
      </vue-scroll>
    </div>
    <PageBtnSet />
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import PageBtnSet from '@/components/PageBtnSet.vue'

export default {
  name: 'Activity',
  components: {
    PageTitle,
    PageBtnSet
  },
  data() {
    return {
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right'
        },
        rail: {},
        bar: {
          keepShow: true,
          background: '#c4c4c4',
          opacity: 1
        }
      },
      newEvent: false
    }
  },
  created() {
    if (
      new Date().getTime() >=
      new Date(process.env.VUE_APP_NEW_EVENT_DATE).getTime()
    ) {
      this.newEvent = true
    }
  }
}
</script>

<style lang="scss">
.activity {
  .activity_content {
    width: 100%;
    height: 500px;
    padding: 0px 35px;
    margin-bottom: 50px;
    overflow-y: auto;

    ul {
      list-style: none;
      padding-left: 0;
      padding-right: 14px;
    }

    ol {
      list-style: none;
      padding-left: 0;
    }

    li {
      font-size: 16px;
      margin-bottom: 8px;
    }

    .activity_title {
      font-size: 16px;
      font-weight: bold;
    }

    .activity_point {
      background: #c3131b;
      font-weight: 500;
    }

    ul,
    ol {
      &.disc,
      &.decimal,
      &.circle {
        padding-left: 24px;
      }
      &.disc {
        list-style: disc;
      }
      &.decimal {
        list-style: decimal;
      }
      &.circle {
        list-style: circle;
      }
    }
  }
}
</style>
